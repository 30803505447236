.ListItem {
	border-radius: 8px !important;
	padding: 0 16px 0 24px !important;
	margin: 20px 0 !important;
	height: 48px;
}

.ListItemIcon {
	min-width: 0 !important;
	padding-right: 16px;
	color: inherit !important;
}

.ListItemTextInset {
	padding-left: 40px;
}
