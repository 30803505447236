.ProposedVehicleDetailModal {
	.VehicleImage {
		animation-name: fade-in;
		animation-duration: 0.5s;
		animation-direction: alternate;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
