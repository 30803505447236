.ImageSlider {
	width: 100%;
	height: 100%;

	:global(.swiper-slide) {
		text-align: center;
		font-size: 18px;
		background: none;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			display: block;
			width: clamp(200px, 100%, 350px);
			object-fit: contain;
			user-select: none;
			aspect-ratio: 1/1;
		}
	}
}
